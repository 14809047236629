import {createRouter, createWebHashHistory} from "vue-router";

// 路由表
const routes = [
  {
    path: "/",
    redirect: "/aicreator"
  },
  {
    path: "",
    redirect: "/aicreator"
  },
  // 登录
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index")
  },
  // 注册
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/register/index")
  },
  // 忘记密码
  {
    path: "/forgetpassword",
    name: "Forget",
    component: () => import("@/views/forget/index")
  },
  // AI 创作
  {
    path: "/aicreator",
    name: "AICreator",
    component: () => import("@/views/aicreator/index")
  },
  // AI 文案优化
  {
    path: "/aicreator/adapt",
    name: "AIAdapt",
    component: () => import("@/views/aicreator/adapt")
  },
  // AI 原链接创作
  {
    path: "/aicreator/link",
    name: "AILink",
    component: () => import("@/views/aicreator/link")
  },
  // AI 创作配置
  {
    path: "/ai-config",
    name: "AIConfig",
    component: () => import("@/views/aicreator/config")
  },
  // AI 创作图片配置
  {
    path: "/ai-config/img",
    name: "AIImgConfig",
    component: () => import("@/views/aicreator/imgConfig")
  },
  // AI 创作历史
  {
    path: "/ai-history",
    name: "AIHistory",
    component: () => import("@/views/aicreator/history")
  },
  // 文本编辑
  {
    path: "/editor",
    name: "Editor",
    component: () => import("@/views/aicreator/editor")
  },
  // AI 混剪
  {
    path: "/aicut",
    name: "AICut",
    component: () => import("@/views/aicut/index")
  },
  // AI 混剪搜索页
  {
    path: "/aicut/search",
    name: "AICutSearch",
    component: () => import("@/views/aicut/search")
  },
  // AI 混剪文案编辑
  {
    path: "/aicut/edit",
    name: "AICutEdit",
    component: () => import("@/views/aicut/edit")
  },
  // AI 混剪配置
  {
    path: "/aicut/config",
    name: "AICutConfig",
    component: () => import("@/views/aicut/config")
  },
  // AI 混剪任务细节
  {
    path: "/aicut/detail",
    name: "AICutDetail",
    component: () => import("@/views/aicut/detail")
  },
  // 音乐推广
  {
    path: "/aicut/music",
    name: "AICutMusic",
    component: () => import("@/views/aicut/music")
  },
  //视频分身
  {
    path: "/aicut/vdsplit",
    name: "AICutVdSplit",
    component: () => import("@/views/aicut/vdsplit")
  },
  {
    path: "/aicut/videoclonedetail",
    name: "VideoCloneDetail",
    component: () => import("@/views/aicut/videoclonedetail")
  },
  // 管理界面
  {
    path: "/manager",
    name: "Manager",
    component: () => import("@/views/manager/index")
  },
  // 视频号详情
  {
    path: "/wxvideo",
    name: "WxVideo",
    component: () => import("@/views/wxvideo/index")
  },
  // 商品管理
  {
    path: "/wxvideoproduct",
    name: "WxVideoProduct",
    component: () => import("@/views/wxvideo/product")
  },
  // 视频号设置
  {
    path: "/wxvideosettings",
    name: "WxVideoSettings",
    component: () => import("@/views/wxvideo/settings")
  },
  // 抖音号详情
  {
    path: "/dyvideo",
    name: "DyVideo",
    component: () => import("@/views/dyvideo/index")
  },
  // 抖音号设置
  {
    path: "/dyvideosettings",
    name: "DyVideoSettings",
    component: () => import("@/views/dyvideo/settings")
  },
  // 好看号详情
  {
    path: "/hkvideo",
    name: "HkVideo",
    component: () => import("@/views/hkvideo/index")
  },
  // 好看号商品管理
  {
    path: "/hkvideoproduct",
    name: "HkVideoProduct",
    component: () => import("@/views/hkvideo/product")
  },
  // 好看号设置
  {
    path: "/hkvideosettings",
    name: "HkVideoSettings",
    component: () => import("@/views/hkvideo/settings")
  },
  // 快手号详情
  {
    path: "/ksvideo",
    name: "KsVideo",
    component: () => import("@/views/ksvideo/index")
  },
  // 快手号商品管理
  {
    path: "/ksvideoproduct",
    name: "KsVideoProduct",
    component: () => import("@/views/ksvideo/product")
  },
  // 快手号设置
  {
    path: "/ksvideosettings",
    name: "KsVideoSettings",
    component: () => import("@/views/ksvideo/settings")
  },
  // 小红书账号详情
  {
    path: "/xhsvideo",
    name: "XhsVideo",
    component: () => import("@/views/xhsvideo/index")
  },
  // 小红书账号设置
  {
    path: "/xhsvideosettings",
    name: "XhsVideoSettings",
    component: () => import("@/views/xhsvideo/settings")
  },
  // 变现
  {
    path: "/shop",
    name: "Shop",
    component: () => import("@/views/shop/index")
  },
  // 商品详情
  {
    path: "/shop/product",
    name: "ShopProduct",
    component: () => import("@/views/shop/product")
  },
  // 确认订单
  {
    path: "/shop/order/confirm",
    name: "ShopOrderConfirm",
    component: () => import("@/views/shop/confirm")
  },
  // 订单页
  {
    path: "/shop/order",
    name: "ShopOrder",
    component: () => import("@/views/shop/order")
  },
  // 订单详情
  {
    path: "/shop/order/detail",
    name: "ShopOrderDetail",
    component: () => import("@/views/shop/detail")
  },
  // 收银台
  {
    path: "/shop/cashier",
    name: "ShopCashier",
    component: () => import("@/views/shop/cashier")
  },
  // 上传商品
  {
    path: "/shop/user/product/add",
    name: "AddUserProduct",
    component: () => import("@/views/product/add")
  },
  // 已上传商品
  {
    path: "/shop/user/product",
    name: "UserProduct",
    component: () => import("@/views/product/index")
  },
  // 已上传商品详情
  {
    path: "/shop/user/product/detail",
    name: "UserProductDetail",
    component: () => import("@/views/product/detail")
  },
  // 个人中心
  {
    path: "/user",
    name: "User",
    component: () => import("@/views/user/index")
  },
  // 消息中心
  {
    path: "/message",
    name: "Massage",
    component: () => import("@/views/message/index.vue")
  },
  // 查看通知
  {
    path: "/notice",
    name: "Notice",
    component: () => import("@/views/message/notice.vue")
  },
  // 会员
  {
    path: "/member",
    name: "Member",
    component: () => import("@/views/member/index.vue")
  },
  // 用户充值记录
  {
    path: "/member/order",
    name: "MemberOrder",
    component: () => import("@/views/member/order.vue")
  },
  {
    path: "/payresult",
    name: "PayResult",
    component: () => import("@/views/member/payResult.vue")
  },
  {
    path: "/promote",
    name: "Promote",
    component: () => import("@/views/promote")
  },
  // 修改账号信息
  {
    path: "/edit/account/info",
    name: "EditAccountInfo",
    component: () => import("@/views/settings/editAccountInfo.vue")
  },
  // 修改登录密码
  {
    path: "/edit/password",
    name: "EditPassword",
    component: () => import("@/views/settings/editLoginPassword.vue")
  },
  // 更改用户昵称
  {
    path: "/edit/nickname",
    name: "EditNickname",
    component: () => import("@/views/settings/editNickname.vue")
  },
  // 反馈与投诉
  {
    path: "/feedback",
    name: "Feedback",
    component: () => import("@/components/user/Feedback.vue")
  },
  // 联系我们
  {
    path: "/contactus",
    name: "Contact",
    component: () => import("@/components/user/ContactUs.vue")
  },
  // 隐私协议
  {
    path: "/privacyagreement",
    name: "PrivacyAgreement",
    component: () => import("@/components/user/PrivacyAgreement.vue")
  },
  // 服务
  {
    path: "/service",
    name: "Service",
    component: () => import("@/views/service/index.vue")
  },
  // 软件版本
  {
    path: "/version",
    name: "SoftwareVersion",
    component: () => import("@/components/user/SoftwareVersion.vue")
  },
  // 上传学生信息
  {
    path: "/studentinformation",
    name: "ImproveInformation",
    component: () => import("@/components/user/ImproveInformation.vue")
  },
  // 推广收益路由规则
  {
    path: "/promote",
    name: "Promote",
    component: () => import("@/views/promote")
  },
  // 提现历史数据
  {
    path: "/promote/withdraw",
    name: "PromoteWithdraw",
    component: () => import("@/views/promote/promoteWithdrew.vue")
  },
  // 提现
  {
    path: "/withdraw",
    name: "Withdraw",
    component: () => import("@/views/promote/Withdraw.vue")
  },
  {
    path: "/bankaccount",
    name: "BankAccount",
    component: () => import("@/views/promote/bankAccount.vue")
  },
  // 更多邀请记录和奖励记录
  {
    path: "/more/change/logs",
    name: "PromoteChange",
    component: () => import("@/views/promote/ChangeLogs.vue")
  },
  {
    path: "/shop/payresult",
    name: "ShopPayResult",
    component: () => import("@/views/shop/payresult.vue")
  },
  // 404页必须放在末尾!!!
  {path: "/:catchAll(.*)", redirect: "/404"},
  {
    path: "/404",
    component: () => import("@/views/404")
  }
];

// 路由对象
const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;

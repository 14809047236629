const getDefaultState = () => {
  return {
    items: [
      {
        path: '/',
        text: '首页',
        icon: 'home',
        tag: 0,
      },
      {
        path: '/add',
        text: '',
        icon: 'add',
        tag: 0,
      },
      {
        path: '/own',
        text: '我的',
        icon: 'own',
        tag: 0,
      },
    ],
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TAB_BAR: (state, item) => {
    state.items[item.index].tag = item.tag
  },
}

const actions = {
  setTabBarTag({ commit }, item) {
    commit('SET_TAB_BAR', item)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

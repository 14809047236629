const getDefaultState = () => {
  return {
    withdrawInfo: {}
  };
};

const state = getDefaultState();

const mutations = {
  SET_WITHDRAW_INFO: (state, withdrawInfo) => {
    state.withdrawInfo = withdrawInfo;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};

import {
  login,
  logout,
  register,
  getUserInfo,
  getUserPriviledges,
  getBrokerTutorial,
  setBrokerTutorial,
  getAccountLevelInfo,
  getSystemLevelConfig,
  getCrawlerErrorInfo,
  delCrawlerErrorInfo
} from "@/api/user";
import {getToken, setToken, removeToken} from "@/utils/auth";
import router from "@/router";

const getDefaultState = () => {
  return {
    userinfo: "",
    priviledges: "",
    tutorial: "",
    levelInfo: "",
    systemLevelConfig: "",
    errorInfo: ""
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
    console.log("setting user token for app,token:", token, "state:", state);
    setToken(JSON.stringify(token));
  },
  SET_USER_INFO: (state, userinfo) => {
    state.userinfo = userinfo;
  },
  SET_USER_PRIVILEDGES: (state, priviledges) => {
    state.priviledges = priviledges;
  },
  SET_USER_TUTORIAL: (state, tutorial) => {
    state.tutorial = tutorial;
  },
  SET_USER_LEVEL_INFO: (state, levelInfo) => {
    state.levelInfo = levelInfo;
  },
  SET_SYSTEM_LEVEL_CONFIG: (state, levelConfig) => {
    state.systemLevelConfig = levelConfig;
  },
  SET_CRAWLER_ERROR_INFO: (state, errorInfo) => {
    state.errorInfo = errorInfo;
  }
};

const actions = {
  // 登录
  login({commit}, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo)
        .then((res) => {
          if (!res.code) {
            // //读取url中的参数
            let urlParams = window.location.href.split("#")[1];
            let pureParams = urlParams.split("?"); //取最后一段?以后的内容
            let onlyParams = pureParams[pureParams.length - 1];
            let localParams = onlyParams.split("&");
            let token = {};
            console.log("login...localParams:", localParams, "onlyParams:", onlyParams, "urlParams:", urlParams,"res:", res);
            for ( let i=0; i < localParams.length; i++) {
                let kv = localParams[i].split("=");
                //console.log("kv:", kv);
                if (kv.length == 2 && kv[0] == "cli") {
                    token.cli = kv[1];
                    //console.log("config cli:", kv[1]);
                }
                if (kv.length == 2 && kv[0] == "cid") {
                   token.cid = kv[1];
                   //console.log("config cid:", kv[1]);
                } 
            }
            token.userid = res.userid;
            token.accesstoken = res.accesstoken;
            commit("SET_TOKEN", token);
            resolve();
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 注册
  register({dispatch}, userInfo) {
    return new Promise((resolve, reject) => {
      register(userInfo)
        .then((res) => {
          if (res.code === 0) {
            dispatch("login", userInfo)
              .then(() => {
                resolve();
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 退出登录
  logout({commit}) {   
    //inform server
    console.log("start request...")
    logout().then((res)=>{
        console.log("user logout...res:", res);
    }).catch((err)=>{
        console.log("user logout err:", err);
    }).finally(()=>{
        removeToken(); // must remove  token  first
        commit("RESET_STATE");
        router.replace(`/login?redirect=${router.currentRoute.value.fullPath}`);
    });
    console.log("after request...");    
  },
  // 获取用户信息
  getUserInfo({commit}) {
    return new Promise((resolve, reject) => {
      getUserInfo()
        .then((res) => {
          if (res.code === 0) {
            commit("SET_USER_INFO", res.desc);
            resolve();
          } else {
            reject(res.desc);
          }
        })
        .catch((error) => {
          reject(error.msg);
        });
    });
  },

  // 获取用户权限
  getPriviledges({commit}) {
    return new Promise((resolve, reject) => {
      if (getToken().userid + "" === "63708814") {
        commit("SET_USER_PRIVILEDGES", {
          dy: true,
          wxvideo: true,
          pdd: true,
          //hkvideo: false,
          xhs: true,
          ks: true
        });
      } else {
        commit("SET_USER_PRIVILEDGES", {
          dy: true,
          wxvideo: true,
          pdd: false,
          //hkvideo: false,
          xhs: true,
          ks: true
        });
      }
      resolve();
      // getUserPriviledges({})
      //   .then((res) => {
      //     if (res.code === 0) {
      //       commit("SET_USER_PRIVILEDGES", {...JSON.parse(res.desc), dy: true});
      //     } else {
      //       commit("SET_USER_PRIVILEDGES", {});
      //     }
      //     resolve();
      //   })
      //   .catch((err) => {
      //     reject(err);
      //   });
    });
  },
  getTutorial({commit}) {
    return new Promise((resolve, reject) => {
      getBrokerTutorial()
        .then((res) => {
          if (res.code === 0) {
            commit("SET_USER_TUTORIAL", JSON.parse(res.desc));
          } else {
            commit("SET_USER_TUTORIAL", {});
          }
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  setTutorial({commit}, tutorial) {
    return new Promise((resolve, reject) => {
      setBrokerTutorial({tag: JSON.stringify(tutorial)})
        .then((res) => {
          if (res.code === 0) {
            commit("SET_USER_TUTORIAL", tutorial);
            resolve();
          } else {
            resolve();
          }
        })
        .catch(() => {
          resolve();
        });
    });
  },
  // 获取用户等级信息
  getLevelInfo({commit}) {
    return new Promise((resolve, reject) => {
      getAccountLevelInfo()
        .then((res) => {
          if (res.code === 0) {
            const broker_id = res.desc.broker_id;
            const level_code = res.desc.level_code;
            const level_config = JSON.parse(res.desc.level_config);
            const priviledge_used = JSON.parse(res.desc.priviledge_used);
            const user_level = JSON.parse(res.desc.user_level);
            commit("SET_USER_LEVEL_INFO", {
              broker_id,
              level_code,
              level_config,
              priviledge_used,
              user_level
            });
          }
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 获取系统权限配置
  getSystemLevelConfig({commit}) {
    return new Promise((resolve, reject) => {
      getSystemLevelConfig()
        .then((res) => {
          if (res.code === 0) {
            commit("SET_SYSTEM_LEVEL_CONFIG", {
              free: JSON.parse(res.desc.free),
              pri: JSON.parse(res.desc.pri),
              sec: JSON.parse(res.desc.sec),
              pro: JSON.parse(res.desc.pro)
            });
            resolve();
          } else {
            commit("SET_SYSTEM_LEVEL_CONFIG", {
              free: {},
              pri: {},
              sec: {},
              pro: {}
            });
          }
          reject(res.desc);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 获取爬虫的错误信息
  getErrorInfo({commit}) {
    return new Promise((resolve, reject) => {
      getCrawlerErrorInfo()
        .then((res) => {
          console.log(res);
          if (res.code === 0) {
            commit(
              "SET_CRAWLER_ERROR_INFO",
              res.desc.map((item) => JSON.parse(item))
            );
          } else {
            commit("SET_CRAWLER_ERROR_INFO", []);
          }
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  delErrorInfo({state, commit}, id) {
    return new Promise((resolve, reject) => {
      delCrawlerErrorInfo(id)
        .then((res) => {
          console.log(res);
          if (res.code === 0) {
            commit(
              "SET_CRAWLER_ERROR_INFO",
              state.getters.errorInfo.filter((item) => item.id !== id)
            );
          }
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

const getters = {
  userinfo: (state) => state.user.userinfo,
  tabbar: (state) => state.tabbar.items,
  priviledges: (state) => state.user.priviledges,
  tutorial: (state) => state.user.tutorial,
  levelInfo: (state) => state.user.levelInfo,
  systemLevelConfig: (state) => state.user.systemLevelConfig,
  wxvideo_ids: (state) => state.wxvideo.wxvideo_ids,
  wxvideo_products: (state) => state.wxvideo.wxvideo_products,
  dyvideo_ids: (state) => state.dyvideo.dyvideo_ids,
  hkvideo_ids: (state) => state.hkvideo.hkvideo_ids,
  ksvideo_ids: (state) => state.ksvideo.ksvideo_ids
};

export default getters;

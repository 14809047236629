const getDefaultState = () => {
  return {
    wxvideo_ids: [],
    wxVideoBindDy: {
      douyin_id: "",
      douyin_name: "",
      douyin_url: "",
      wxVideoDealType: ""
    }, // 抖音绑定信息
    wxVideoDealType: "1", // 微信视频处理模式
    wxVideoRulers: {
      like_count: 0,
      sort: "",
      timeValue: 0,
      topRulers: false,
      useNicknameWatermark: false,
      timeActive: 3,
      releaseCount: 3
    }, // 视频原创规则
    subtitle: "",
    wxvideo_products: {},
    // 私信自动回复数据
    replyList: {
      content: [
        {
          id: -1,
          keyword: "默认回复",
          randomReply: false,
          text_list: [
            {
              id: 0,
              text: "",
              type: "text",
              size: 0
            }
          ]
        }
      ]
    }
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_WXVIDEO_IDS: (state, list) => {
    state.wxvideo_ids = list;
  },
  SET_WXVIDEO_BIND_DY: (state, dyBindInfo) => {
    state.wxVideoBindDy.douyin_id = dyBindInfo.douyin_id || "";
    state.wxVideoBindDy.douyin_name = dyBindInfo.douyin_name || "";
    state.wxVideoBindDy.douyin_url = dyBindInfo.douyin_url || "";
    state.wxVideoBindDy.wxVideoDealType = dyBindInfo.wxVideoDealType || "";
  },
  SET_WXVIDEO_TYPE: (state, type) => {
    state.wxVideoDealType = type;
  },
  SET_WXVIDEO_RULERS: (state, ruler) => {
    state.wxVideoRulers.like_count = ruler.like;
    state.wxVideoRulers.timeValue = ruler.limited_time;
    state.wxVideoRulers.sort = ruler.sort;
    state.wxVideoRulers.topRulers = ruler.topRulers || false;
    state.wxVideoRulers.useNicknameWatermark = ruler.useNicknameWatermark;
    state.wxVideoRulers.timeActive = ruler.timeActive;
    state.wxVideoRulers.releaseCount = ruler.releaseCount;
  },
  SET_WXVIDEO_SUBTITLE: (state, subtitle) => {
    state.subtitle = subtitle;
  },
  SET_WXVIDEO_PRODUCTS: (state, products) => {
    state.wxvideo_products = products;
  },
  SET_REPLY_LIST: (state, content) => {
    state.replyList.content = JSON.parse(content);
  }
};

const actions = {
  setWxvideoIds({commit}, list) {
    commit("SET_WXVIDEO_IDS", list);
  },
  setWxvideoProducts({commit}, products) {
    commit("SET_WXVIDEO_PRODUCTS", products);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

import router from "./router";
import store from "./store";
import {Toast} from "vant";
import {setToken, getToken, setAppInfo, getAppInfo} from "./utils/auth";
import {getUserInfo} from "./api/user";

const whiteList = [
  "/login",
  "/register",
  "/forgetpassword",
  "/privacyagreement",
  "/shop/cashier",
  "/shop/payresult",
  "/payresult",
  "/404"
]; // 不需要重定向白名单

// 隐藏首屏加载
function hideFirstLoading() {
  document.querySelector(".loader").style.display = "none";
}

// hideFirstLoading();

router.beforeEach(async (to, from, next) => {
  // 确定用户是否已登录
  var appInfo = getAppInfo(); 
  if (appInfo != null) {
      console.log("保存的appinfo:", JSON.stringify(appInfo));
  }
  console.log("任何路由之前的访问，地址栏：cli:", to.query.cli, "cid:", to.query.cid, "to:", JSON.stringify(to.query), "from:", from);  
  if (typeof to.query.cli != 'undefined' && typeof to.query.cid != 'undefined') {
      console.log("设置cli和cid:", to.query.cli, "cid:", to.query.cid);
      setAppInfo(
        JSON.stringify({cli:to.query.cli, cid:to.query.cid})
      );
  }  
  if (to.query.uid && to.query.actoken) {
    var token = getToken();
    if (token != null && (token.userid != to.query.uid || token.accesstoken != to.query.actoken)){
       console.log("登录已过期，重新登录");
       next({path: "/login"});
    }else {      
      console.log("setting app, uid:", to.query.uid, "token:", to.query.actoken, "cid:", token.cid);   
      var appInfo = getAppInfo();
      if (appInfo != null){
          setToken(
            JSON.stringify({userid: to.query.uid, accesstoken: to.query.actoken, cli: appInfo.cli, cid:appInfo.cid})
          );
          //通知服务器绑定当前的cid和uid
          console.log("绑定当前cid和userid, cid:", to.query.cid, "userid:", token.userid);
          getUserInfo().then((res)=>{console.log("get user info res:", res)});  
      }else{
          console.log("非客户端访问，只记录原来的token");
          setToken(
            JSON.stringify({userid: to.query.uid, accesstoken: to.query.actoken})
          );
      }      
    }
  }

  if (getToken()) {
    if (to.path === "/login" || to.path === "/register") {
      hideFirstLoading();
      // 如果已登录，请重定向到首页
      next({path: "/"});
    } else {
      // 确定用户是否已通过getUserInfo获得数据
      if (store.getters.userinfo) {
        hideFirstLoading();
        next();
      } else {
        try {
          // 获取用户信息
          await store.dispatch("user/getUserInfo");
          // 获取用户权限、等级信息
          try {
            await Promise.all([
              store.dispatch("user/getPriviledges"),
              store.dispatch("user/getTutorial"),
              store.dispatch("user/getLevelInfo"),
              store.dispatch("user/getSystemLevelConfig")
            ]);
          } catch (err) {
            if (`${err}`.includes("not a valid visit")) {
              Toast.fail("登录已过期\n请重新登录");
            } else {
              Toast.fail(err || "Error");
            }
          }
          hideFirstLoading();
          next();
        } catch (error) {
          // 删除token并转到登录页面重新登录
          await store.dispatch("user/logout");
          if (`${error}`.includes("not a valid visit")) {
            Toast.fail("登录已过期\n请重新登录");
          } else {
            Toast.fail(error || "Error");
          }
          hideFirstLoading();
          next(`/login?redirect=${to.fullPath}`);
        }
      }
    }
  } else {
    hideFirstLoading();
    if (whiteList.indexOf(to.path) !== -1) {
      // 在白名单中，直接跳转
      next();
    } else {
      // 没有访问权限的其他页面将重定向到登录页面
      next(`/login?redirect=${to.fullPath}`);
    }
  }
});

const getDefaultState = () => {
  return {
    xhsvideo_ids: [],
    xhsVideoBindDy: {
      douyin_id: "",
      douyin_name: "",
      douyin_url: "",
      xhsVideoDealType: ""
    }, // 抖音绑定信息
    xhsVideoDealType: "1", // 视频处理模式
    xhsVideoRulers: {
      like_count: 0,
      sort: "",
      timeValue: 0,
      topRulers: false,
      useNicknameWatermark: false,
      timeActive: 3,
      releaseCount: 3
    }, // 视频原创规则
    subtitle: "",
    // 私信自动回复数据
    replyList: {
      content: [
        {
          id: -1,
          keyword: "默认回复",
          randomReply: false,
          text_list: [
            {
              id: 0,
              text: "",
              type: "text",
              size: 0
            }
          ]
        }
      ]
    }
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_XHSVIDEO_IDS: (state, list) => {
    state.xhsvideo_ids = list;
  },
  SET_XHSVIDEO_BIND_DY: (state, dyBindInfo) => {
    state.xhsVideoBindDy.douyin_id = dyBindInfo.douyin_id || "";
    state.xhsVideoBindDy.douyin_name = dyBindInfo.douyin_name || "";
    state.xhsVideoBindDy.douyin_url = dyBindInfo.douyin_url || "";
    state.xhsVideoBindDy.xhsVideoDealType = dyBindInfo.xhsVideoDealType || "";
  },
  SET_XHSVIDEO_TYPE: (state, type) => {
    state.xhsVideoDealType = type;
  },
  SET_XHSVIDEO_RULERS: (state, ruler) => {
    state.xhsVideoRulers.like_count = ruler.like;
    state.xhsVideoRulers.timeValue = ruler.limited_time;
    state.xhsVideoRulers.sort = ruler.sort;
    state.xhsVideoRulers.topRulers = ruler.topRulers || false;
    state.xhsVideoRulers.useNicknameWatermark = ruler.useNicknameWatermark;
    state.xhsVideoRulers.timeActive = ruler.timeActive;
    state.xhsVideoRulers.releaseCount = ruler.releaseCount;
  },
  SET_XHSVIDEO_SUBTITLE: (state, subtitle) => {
    state.subtitle = subtitle;
  },
  SET_REPLY_LIST: (state, content) => {
    state.replyList.content = JSON.parse(content);
  }
};

const actions = {
  setXhsvideoIds({commit}, list) {
    commit("SET_XHSVIDEO_IDS", list);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

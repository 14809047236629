import request from "@/utils/request";

/**
 * 发送消息
 * @param {String} msg JSON 格式 {"broker_id","question","agent_step","ui_location"}
 * @returns
 */
export function sendAiAgentChatMsg(msg) {
  return request({
    url: "/aiagent/chat",
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: msg
  });
}

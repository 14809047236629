const tokenKey = 'broker'
const appKey = 'appinfo'

/**
 * @description 获取token
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getToken() {
  return JSON.parse(localStorage.getItem(tokenKey))
}

/**
 * @description 存储token
 * @param token
 * @returns {void|*}
 */
export function setToken(token) {
  return localStorage.setItem(tokenKey, token)
}

export function setAppInfo(appInfo) {
  return localStorage.setItem(appKey, appInfo)
}

export function getAppInfo() {
  return JSON.parse(localStorage.getItem(appKey))
}

/**
 * @description 移除token
 * @returns {void|Promise<void>}
 */
export function removeToken() {
  return localStorage.removeItem(tokenKey)
}

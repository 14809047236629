const getDefaultState = () => {
  return {
    qrCode: "",
  };
};

const state = getDefaultState();

const mutations = {
  RESET_QR_CODE: (state, qrcode) => {
    state.qrCode = qrcode;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};

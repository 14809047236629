const getDefaultState = () => {
  return {
    ksvideo_ids: [],
    ksVideoBindDy: {
      douyin_id: "",
      douyin_name: "",
      douyin_url: "",
      ksVideoDealType: "",
    }, // 抖音绑定信息
    ksVideoDealType: "1", // 微信视频处理模式
    ksVideoRulers: {
      like_count: 0,
      sort: "",
      timeValue: 0,
      topRulers: false,
      useNicknameWatermark: false,
      timeActive: 3,
      releaseCount: 3,
    }, // 视频原创规则
    subtitle: "",
    ksvideo_products: {},
    // 私信自动回复数据
    replyList: {
      content: [
        {
          id: -1,
          keyword: "默认回复",
          randomReply: false,
          text_list: [
            {
              id: 0,
              text: "",
              type: "text",
              size: 0,
            },
          ],
        },
      ],
    },
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_KSVIDEO_IDS: (state, list) => {
    state.ksvideo_ids = list;
  },
  SET_KSVIDEO_BIND_DY: (state, dyBindInfo) => {
    state.ksVideoBindDy.douyin_id = dyBindInfo.douyin_id || "";
    state.ksVideoBindDy.douyin_name = dyBindInfo.douyin_name || "";
    state.ksVideoBindDy.douyin_url = dyBindInfo.douyin_url || "";
    state.ksVideoBindDy.ksVideoDealType = dyBindInfo.ksVideoDealType || "";
  },
  SET_KSVIDEO_TYPE: (state, type) => {
    state.ksVideoDealType = type;
  },
  SET_KSVIDEO_RULERS: (state, ruler) => {
    state.ksVideoRulers.like_count = ruler.like;
    state.ksVideoRulers.timeValue = ruler.limited_time;
    state.ksVideoRulers.sort = ruler.sort;
    state.ksVideoRulers.topRulers = ruler.topRulers || false;
    state.ksVideoRulers.useNicknameWatermark = ruler.useNicknameWatermark;
    state.ksVideoRulers.timeActive = ruler.timeActive;
    state.ksVideoRulers.releaseCount = ruler.releaseCount;
  },
  SET_KSVIDEO_SUBTITLE: (state, subtitle) => {
    state.subtitle = subtitle;
  },
  SET_KSVIDEO_PRODUCTS: (state, products) => {
    state.ksvideo_products = products;
  },
  SET_REPLY_LIST: (state, content) => {
    state.replyList.content = JSON.parse(content);
  },
};

const actions = {
  setKsvideoIds({ commit }, list) {
    commit("SET_KSVIDEO_IDS", list);
  },
  setKsvideoProducts({ commit }, products) {
    commit("SET_KSVIDEO_PRODUCTS", products);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

import {
  Tabbar,
  TabbarItem,
  Button,
  Image as VanImage,
  Form,
  Field,
  CellGroup,
  NavBar,
  Icon,
  Search,
  Dialog,
  Popup,
  Checkbox,
  CheckboxGroup,
  DropdownMenu,
  DropdownItem,
  Tab,
  Tabs,
  PullRefresh,
  Overlay,
  Switch,
  RadioGroup,
  Radio,
  Empty,
  Cell,
  Uploader,
  Pagination,
  Swipe,
  SwipeItem,
  Loading,
  Picker,
  Popover,
  Stepper
} from "vant";

const vanTList = [
  Tabbar,
  TabbarItem,
  Button,
  VanImage,
  Form,
  Field,
  CellGroup,
  NavBar,
  Icon,
  Search,
  Dialog,
  Popup,
  Checkbox,
  CheckboxGroup,
  DropdownMenu,
  DropdownItem,
  Tab,
  Tabs,
  PullRefresh,
  Overlay,
  Switch,
  RadioGroup,
  Radio,
  Empty,
  Cell,
  Uploader,
  Pagination,
  Swipe,
  SwipeItem,
  Loading,
  Picker,
  Popover,
  Stepper
];

const vanTComponents = {
  install: (Vue) => {
    vanTList.forEach((item) => {
      Vue.use(item);
    });
  }
};

export default vanTComponents;

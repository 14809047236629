const getDefaultState = () => {
  return {
    videoInfo: {},
    videoConfig: {},
    dHotSearchInfo: {},
    adaptText: ""
  };
};

const state = getDefaultState();

const mutations = {
  SET_VIDEO_INFO: (state, videoInfo) => {
    state.videoInfo = videoInfo;
  },
  SET_VIDEO_CONFIG: (state, videoConfig) => {
    state.videoConfig = videoConfig;
  },
  SET_DHOT_SEARCH_INFO: (state, searchInfo) => {
    state.dHotSearchInfo = searchInfo;
  },
  SET_ADAPT_TEXT: (state, text) => {
    state.adaptText = text;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};

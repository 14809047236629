const getDefaultState = () => {
  return {
    noticeInfo: {}
  };
};

const state = getDefaultState();

const mutations = {
  SET_NOTICE_INFO: (state, noticeInfo) => {
    state.noticeInfo = noticeInfo;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};

import request from "@/utils/request";

// 登录
export function login(params) {
  return request({
    url: "login",
    method: "post",
    params
  });
}

export function logout() {
  return request({
    url: "logout",
    method: "post",
    params:{}
  });
}

// 注册
export function register(params) {
  return request({
    url: "register/email",
    method: "post",
    params
  });
}

// 获取用户信息
export function getUserInfo() {
  return request({
    url: "mobile/getuserinfo",
    method: "post"
  });
}

// 修改用户头像
export function setUserPic(params) {
  return request({
    url: "user/setuserpic",
    method: "post",
    params
  });
}

// 修改用户昵称
export function setNickName(params) {
  return request({
    url: "user/setnickname",
    method: "post",
    params
  });
}

// 修改登录密码
export function changePassWord(params) {
  return request({
    url: "mobile/changepass",
    method: "post",
    params
  });
}

/**
 * 获取用户权限
 * @returns
 */
export function getUserPriviledges(params) {
  return request({
    url: "/get/user/priviledges",
    method: "post",
    params
  });
}

/**
 * 设置用户权限
 * @param {String} priviledges
 * @returns
 */
export function setUserPriviledges(params) {
  return request({
    url: "/set/account/priviledges",
    method: "post",
    params
  });
}

/** 投诉与反馈
 *  @url /add/system/feedback
 *  @param {String} feed_id  feed_info
 *  @param {object} feed_info
 */
export function addSystemFeedback(params, data) {
  return request({
    url: "/add/system/feedback",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    method: "post",
    params,
    data
  });
}

/** 获取反馈信息
 * @url /get/system/feedbacks
 */
export function getSystemFeedback() {
  return request({
    url: "/get/system/feedbacks",
    method: "post"
  });
}

/** 删除反馈信息
 * @url /del/system/feedback
 * @param {Sting} feed_id
 */
export function delSystemFeedback(params) {
  return request({
    url: "/del/system/feedback",
    method: "post",
    params
  });
}

/** 获取系统通知
 * @url /get/system/notifications
 */
export function getSystemNotifications() {
  return request({
    url: "/get/system/notifications",
    method: "post"
  });
}

/** 获取已读系统通知
 * @url /get/read/system/notification
 */
export function getReadSystemNotification() {
  return request({
    url: "/get/read/system/notification",
    method: "post"
  });
}

/** 设置已读系统通知
 * @url /read/system/notifications
 * @param {Sting} notice
 */
export function readSystemNotification(params) {
  return request({
    url: "/read/system/notification",
    method: "post",
    params
  });
}

/** 获取客户在线状态 */
export function getCustomOnlineStatus() {
  return request({
    url: "/get/cs/online",
    method: "post"
  });
}

/** 获取图片验证码 */
export function getCaptcha() {
  return request({
    url: "/getcaptcha",
    method: "post"
  });
}

/** 发送短信验证码
 * @param {Sting} phone
 * @param {Sting} captchaid 图片验证码返回 id
 * @param {Sting} captcha 图片验证码
 */
export function sendPhoneSmsCode(params) {
  return request({
    url: "/phone/smscode",
    method: "post",
    params
  });
}

/** 短信验证码修改密码
 * @param {Sting} code
 * @param {Sting} newpass
 */
export function changePassWithSms(params) {
  return request({
    url: "/change/passwithsms",
    method: "post",
    params
  });
}

/** 检查当前账号是否显示推荐码 */
export function checkAccountRefno(params) {
  return request({
    url: "/check/account/refno",
    method: "post",
    params
  });
}

/** 获取客服聊天消息 */
export function getCsChatlogs() {
  return request({
    url: "/get/cs/chatlogs",
    method: "post"
  });
}

/** 发送聊天消息 */
export function addCsChatlog(data) {
  return request({
    url: "/add/cs/chatlog",
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    data
  });
}

/**
 * 获取会员信息
 * @url /get/accountlevel/products
 */
export function getLevelProducts(params = {}) {
  return request({
    url: "/get/accountlevel/products",
    method: "post",
    params
  });
}

/**
 * 提交订单
 * @url /add/accountlevel/charge/order
 * @param {String} level_id  参数值在上一个接口获取
 */
export function addLevelChargeOrder(params) {
  return request({
    url: "/add/accountlevel/charge/order",
    method: "post",
    params
  });
}

/**
 * 获取订单
 * /get/accountlevel/charge/orders
 */
export function getLevelChargeOrders(params = {}) {
  return request({
    url: "/get/accountlevel/charge/orders",
    method: "post",
    params
  });
}

/**
 * 取消订单
 * @param {String} order_no 订单编号
 * @url /invalidate/accountlevel/charge/order
 */
export function invalidateOrder(params) {
  return request({
    url: "/invalidate/accountlevel/charge/order",
    method: "post",
    params
  });
}

/**
 * 查询订单支付状态
 * @url /get/accountlevel/charge/order/status
 * @param {String} order_no  生成订单后，查询订单结果
 */
export function getOrderPaymentStatus(params) {
  return request({
    url: "/get/accountlevel/charge/order/status",
    method: "post",
    params
  });
}

/**
 * 查看公开订单支付的结果
 * @url /get/accountlevel/charge/public/order/status
 * @param {String} order_no  订单编号
 */
export function getAccountLevelStatus(params) {
  return request({
    url: "/get/accountlevel/charge/public/order/status",
    method: "post",
    params
  });
}

/**
 * 获取账号学生身份倒计时时间
 * @url /get/account/student
 */
export function getAccountStudent(params = {}) {
  return request({
    url: "/get/account/student",
    method: "post",
    params
  });
}

/**
 * 获取账号当前级别数据
 * @url /get/account/level/info
 */
export function getAccountLevelInfo(params = {}) {
  return request({
    url: "/get/account/level/info",
    method: "post",
    params
  });
}

/**
 * 获取推广收益余额
 * @url /get/account/promote/balance
 */
export function getPromoteBalance(params = {}) {
  return request({
    url: "/get/account/promote/balance",
    method: "post",
    params
  });
}

/**
 * 获取推广账户帐变日志
 * @url /get/account/promote/balance/change/logs
 */
export function getPromoteAccountLogs(params = {}) {
  return request({
    url: "/get/account/promote/balance/change/logs",
    method: "post",
    params
  });
}

/**
 * 获取账号拿到的提成明细
 * @url /get/account/promote/distributed/logs
 */
export function getPromoteDistributedLogs(params = {}) {
  return request({
    url: "/get/account/promote/distributed/logs",
    method: "post",
    params
  });
}

/**
 * 获取推广下线账号信息（仅包含两级）
 * @url /get/account/promote/sons
 */
export function getPromoteAccountSons(params = {}) {
  return request({
    url: "/get/account/promote/sons",
    method: "post",
    params
  });
}

/**
 * 获取拉新奖励日志
 * @url /get/account/invite/reward/logs
 */
export function getInviteRewardLogs(params = {}) {
  return request({
    url: "/get/account/invite/reward/logs",
    method: "post",
    params
  });
}

/**
 * 提现银行卡信息列表
 * @url /get/account/promote/bankaccounts
 */
export function getPromoteAccountList(params = {}) {
  return request({
    url: "/get/account/promote/bankaccounts",
    method: "post",
    params
  });
}

/**
 * 添加提现账号信息
 * @url /add/account/promote/bankaccount
 * @param { String } bank_symbol(银行简称，CCB，BOC等..) 银行简称
 * @param { String } bank_account
 * @param { String } bank_account_no  银行卡号
 * @param { String } bank_branch  开户行信息
 */
export function addPromoteAccount(params) {
  return request({
    url: "/add/account/promote/bankaccount",
    method: "post",
    params
  });
}

/**
 * 删除账号信息
 * @url /del/account/promote/bankaccount
 * @param { String | Number } item_id   账号id
 */
export function delPromoteAccount(params) {
  return request({
    url: "/del/account/promote/bankaccount",
    method: "post",
    params
  });
}

/**
 * 编辑账号信息
 * @url /edit/account/promote/bankaccount
 * @param { String | Number } item_id   账号id
 * @param { Boolean } is_current    是否设置为当前有效
 * @param { String } bank_branch    开户行信息
 * @param { String } bank_account_no  账户卡号
 * @param { String } bank_account
 * @param { String } bank_symbol 银行简称
 */
export function editPromoteAccount(params) {
  return request({
    url: "/edit/account/promote/bankaccount",
    method: "post",
    params
  });
}

/**
 * 提现申请记录
 * @url /get/account/promote/withdraw/list
 */
export function getPromoteWithDrawList(params = {}) {
  return request({
    url: "/get/account/promote/withdraw/list",
    method: "post",
    params
  });
}

/**
 * 申请提现，默认会以设置为当前有效的账号提现
 * @url /add/account/promote/withdraw/order
 * @param { Number } amount  提现金额
 */
export function addPromoteWithDrawOrder(params) {
  return request({
    url: "/add/account/promote/withdraw/order",
    method: "post",
    params
  });
}

/**
 * 撤销提现
 * @url /revoke/account/promote/withdraw/order
 * @param { String } order_no   提现订单号
 */
export function revokePromoteWithDrawOrder(params) {
  return request({
    url: "/revoke/account/promote/withdraw/order",
    method: "post",
    params
  });
}

/**
 * 获取新手引导访问状态
 * @url /get/broker/tutorial
 */
export function getBrokerTutorial() {
  return request({
    url: "/get/broker/tutorial",
    method: "post"
  });
}

/**
 * 设置已经完成过新手引导
 * @url /set/broker/tutorial
 * @param { String } tag JSON
 */
export function setBrokerTutorial(params) {
  return request({
    url: "/set/broker/tutorial",
    method: "post",
    params
  });
}

/**
 * 获取会员等级权限配置
 * @url /get/system/account/level/config
 */
export function getSystemLevelConfig() {
  return request({
    url: "/get/system/account/level/config",
    method: "post"
  });
}

/**
 * 获取用户 IP 地址
 * @returns {String} ip
 */
export function getUserIp() {
  return request({
    url: "/get/user/ip",
    method: "post"
  });
}

/**
 * 获取爬虫错误信息
 * @returns {String} error_info json {id, cid, broker_id, err_msg, status, type, update_time, create_time[, acc_info: {id, account_id, ...}]}
 */
export function getCrawlerErrorInfo() {
  return request({
    url: "/get/crawler/error/info",
    method: "post"
  });
}

/**
 * 删除爬虫错误信息
 * @param {String} id
 */
export function delCrawlerErrorInfo(id) {
  return request({
    url: "/rem/crawler/error/info",
    method: "post",
    params: {id}
  });
}

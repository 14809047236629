const getDefaultState = () => {
  return {
    activeNavBar: "dy",
  };
};

const state = getDefaultState();

const mutations = {
  SET_ACTIVE_NAVBAR: (state, activeNavBar) => {
    state.activeNavBar = activeNavBar;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};

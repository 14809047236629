import axios from "axios";
import store from "@/store";
import router from "@/router";
import {getToken, getAppInfo} from "./auth";

const service = axios.create({
  baseURL: process.env.VUE_APP_BROKER,
  timeout: 30000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
  },
  params: {}
});

service.interceptors.request.use(
  (config) => {
    const token = getToken();   
    //console.log("request with token:", JSON.stringify(token));
    const appInfo = getAppInfo();
    if (token) {
      //login before...
      config.params.uid = token.userid;
      config.params.actoken = token.accesstoken;
      if (appInfo) {
          //console.log("request with appinfo:", JSON.stringify(appInfo));
          config.params.cid = appInfo.cid;
          config.params.cli = appInfo.cli;
      }
      
    }else{
        // //not login...
        // let urlParams = window.location.href.split("#")[1];
        // let pureParams = urlParams.split("?"); //取最后一段?以后的内容
        // let onlyParams = pureParams[pureParams.length - 1];
        // let localParams = onlyParams.split("&");
        // //console.log("localParams:", localParams, "onlyParams:", onlyParams, "urlParams:", urlParams);
        // for ( let i=0; i < localParams.length; i++) {
        //     let kv = localParams[i].split("=");
        //     //console.log("kv:", kv);
        //     if (kv.length == 2 && kv[0] == "cli") {
        //         config.params.cli = kv[1];
        //         //console.log("config cli:", kv[1]);
        //     }
        //     if (kv.length == 2 && kv[0] == "cid") {
        //        config.params.cid = kv[1];
        //        //console.log("config cid:", kv[1]);
        //     } 
        // }
    }
    //console.log("config.params:", config.params);    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);



service.interceptors.response.use(
  (response) => {
    const {code, desc, accesstoken} = response.data;
    if (code === 0) {
      if (accesstoken) {
        // 兼容后端返回数据不统一
        return {
          userid: response.data.userid,
          accesstoken: response.data.accesstoken
        };
      } else {
        return response.data;
      }
    } else {
      if (desc === "not a valid visit" || desc === "非法访问！") {
        if (router.currentRoute.value.name !== "Login") {
          store.dispatch("user/logout");
        }
      }
      return {code, desc};
    }
  },
  (error) => {
    let code = 0;
    let {message} = error;

    if (message === "Network Error") {
      message = "后端接口连接异常";
    }
    if (message.includes("timeout")) {
      message = "后端接口请求超时";
    }
    if (message.includes("Request failed with status code")) {
      code = Number(message.substr(message.length - 3));
      if (code === 401) {
        message = "请重新登录!";
        if (router.currentRoute.value.name !== "Login") {
          store.dispatch("user/logout");
        }
      } else {
        message = "后端接口" + code + "异常";
      }
    }
    return Promise.reject({
      code: code,
      msg: message
    });
  }
);

export default service;

<template>
  <div class="container">
    <router-view v-slot="{Component}">
      <Transition :name="transitionName">
        <component :is="Component" />
      </Transition>
    </router-view>
    <!-- 底部导航 -->
    <van-tabbar route v-if="showTarBar" @change="onChange">
      <van-tabbar-item to="/aicreator">
        <span>AI创作</span>
        <template #icon="props">
          <img
            class="aicreator-icon"
            :class="{active: props.active}"
            :src="
              props.active
                ? require('@/assets/tarbar/tarbar_aicreator_active.png')
                : require('@/assets/tarbar/tarbar_aicreator.png')
            "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/manager">
        <span>账号管理</span>
        <template #icon="props">
          <img
            style="object-fit: contain"
            class="manager-icon"
            :class="{active: props.active}"
            :src="
              props.active
                ? require('@/assets/tarbar/tarbar_manager_active.png')
                : require('@/assets/tarbar/tarbar_manager.png')
            "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/shop">
        <span>变现</span>
        <template #icon="props">
          <img
            class="shop-icon"
            :class="{active: props.active}"
            :src="
              props.active
                ? require('@/assets/tarbar/tarbar_shop_active.png')
                : require('@/assets/tarbar/tarbar_shop.png')
            "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/user" :class="{unread: hasUnReadInfo}">
        <span>我的</span>
        <template #icon="props">
          <img
            class="user-icon"
            :class="{active: props.active}"
            :src="
              props.active
                ? require('@/assets/tarbar/tarbar_user_active.png')
                : require('@/assets/tarbar/tarbar_user.png')
            "
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>

    <!-- <FloatBtn
      class="ai-agent"
      v-show="!showAIAgent && showAgentBtn"
      @shortTouch="showAIAgent = true"
    >
      <div class="agent-icon">
        <van-image
          :src="require('@/assets/icon/aiagent.png')"
          width="1.5rem"
          height="1.5rem"
        />
      </div>
    </FloatBtn> -->

    <van-popup
      v-model:show="showAIAgent"
      position="bottom"
      :style="{
        height: '85%',
        overflow: 'visible',
        borderRadius: '20px 20px 0 0'
      }"
      class="agent-popup"
    >
      <div class="agent-container">
        <div class="header">
          <div class="header-left" />
          <div class="header-icon">
            <div class="agent-icon">
              <van-image
                :src="require('@/assets/icon/aiagent.png')"
                width="2.0625rem"
                height="2.0625rem"
              />
            </div>
          </div>
          <div class="header-right" @click="showAIAgent = false">收起</div>
        </div>

        <div class="content">
          <div class="list">
            <div
              :class="`item ${item.to === 'user' ? 'item-left' : 'item-right'}`"
              v-for="item in msgList"
              :key="item.id"
            >
              <span v-html="item.content" />
            </div>
            <div class="item item-left" v-show="isAgentLoading">
              <van-loading type="spinner" size="1.25rem" />
            </div>
          </div>
        </div>

        <div class="footer">
          <div class="field-wrap">
            <van-field v-model.trim="iptValue" placeholder="提问AI..." />
            <div class="btn" v-show="iptValue" @click="handleSendMsg">
              <van-icon name="arrow-up" />
            </div>
          </div>
          <div class="tip">内容由AI生成，不能完全保证真实</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import {computed, provide, ref, reactive, watch} from "vue";
import * as echarts from "echarts";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {sendAiAgentChatMsg} from "@/api/aiagent";
import {
  getSystemFeedback,
  getSystemNotifications,
  getReadSystemNotification,
  getCsChatlogs
} from "@/api/user";
import FloatBtn from "@/components/floatBtn";
import {Toast} from "vant";

provide("echarts", echarts);

const store = useStore();
const router = useRouter();
const route = useRoute();

const showAIAgent = ref(false);
const msgList = ref([
  {
    id: 0,
    from: "ai",
    to: "user",
    content: "欢迎使用视云AI，我是您的创作助手，看看我能帮你什么忙吧！"
  }
]);
const iptValue = ref("");
const isAgentLoading = ref(false);
let curAgentStep = "entry";

const hasUnReadInfo = ref(false); // 是否有未读的消息
const lastReadTime = reactive({
  withdraw: localStorage.getItem(`withdraw-${store.getters.userinfo.Id}`) || 0,
  feedback: localStorage.getItem(`feedback-${store.getters.userinfo.Id}`) || 0
});

const transitionName = ref("");

// 部分页面不显示 AI 助理按钮
const showAgentBtn = computed(() => {
  const path = route.path;
  const pathArr = [
    "/login",
    "/register",
    "/forgetpassword",
    "/privacyagreement",
    "/shop/payresult",
    "/shop/cashier",
    "/payresult",
    "/404"
  ];
  return pathArr.every((item) => item !== path);
});

// 动态控制是否显示底部导航
const showTarBar = computed(() => {
  // 获取当前路由地址
  const path = route.path;
  // 需要显示的路由地址
  const pathArr = ["/aicreator", "/shop", "/manager", "/user"];
  if (pathArr.indexOf(path) >= 0) {
    return true;
  }
  return false;
});

router.beforeEach((to, from) => {
  const list = [
    "/aicut/search",
    "/aicut/config",
    "/aicut/edit",
    "/aicut/detail"
  ];
  if (list.includes(to.path) && list.includes(from.path)) {
    const toIndex = list.findIndex((item) => item === to.path);
    const fromIndex = list.findIndex((item) => item === from.path);
    if (toIndex > fromIndex) {
      transitionName.value = "fold-left";
    } else if (toIndex < fromIndex) {
      transitionName.value = "fold-right";
    } else {
      transitionName.value = "";
    }
  } else {
    transitionName.value = "";
  }

  if (from.path === "/login") {
    getNewInfo();
  }
});

const handleSendMsg = async () => {
  try {
    if (!iptValue.value) return;
    isAgentLoading.value = true;
    msgList.value.push({
      id: new Date().getTime(),
      from: "user",
      to: "ai",
      content: iptValue.value.replace(/[\n]/g, "<br/>").replace(" ", "&nbsp;")
    });
    const text = iptValue.value;
    iptValue.value = "";
    const queryList = [];
    Object.keys(route.query).forEach((key) => {
      queryList.push(`${key}=${route.query[key]}`);
    });
    const res = await sendAiAgentChatMsg(
      JSON.stringify({
        question: text,
        agent_step: curAgentStep,
        ui_location: `${route.path}${
          queryList.length ? `?${queryList.join("&")}` : ""
        }`
      })
    );
    isAgentLoading.value = false;
    if (res.code === 0) {
      msgList.value.push({
        id: new Date().getTime(),
        from: "ai",
        to: "user",
        content: res.desc.agent_reply
          .replace(/[\n]/g, "<br/>")
          .replace(" ", "&nbsp;")
      });
    } else {
      msgList.value.push({
        id: new Date().getTime(),
        from: "ai",
        to: "user",
        content: res.desc.replace(/[\n]/g, "<br/>").replace(" ", "&nbsp;")
      });
    }
  } catch {
    isAgentLoading.value = false;
    Toast({
      type: "fail",
      message: "网络故障\n请检查网络",
      icon: require("@/assets/icon/fail_icon.png")
    });
  }
};

const onChange = (e) => {
  if (e === 2) {
    hasUnReadInfo.value = false;
  }
};

const getNewInfo = async () => {
  try {
    const res = await Promise.all([
      getSystemFeedback(),
      getSystemNotifications(),
      getReadSystemNotification(),
      getCsChatlogs()
    ]);
    const feedbackData = res[0];
    const csMsgsData = res[3];
    if (feedbackData.code === 0) {
      let feedbackList = [];
      let withdrawList = [];
      feedbackData.desc.forEach((item) => {
        const feedInfo = JSON.parse(JSON.parse(item).feed_info);
        const feed_id = feedInfo.feed_id;

        if (feed_id.includes("feedback")) {
          if (
            feedInfo.reply &&
            feedInfo.content.feedback_type.findIndex(
              (item) => item === "学生证审核"
            ) === -1
          ) {
            feedbackList.push(feedInfo);
          }
        } else if (feed_id.includes("withdraw")) {
          feedInfo.content = JSON.parse(feedInfo.content);
          withdrawList.push(feedInfo);
        }
      });
      if (
        feedbackList.some((item) => item.reply_time > lastReadTime.feedback) ||
        withdrawList.some(
          (item) =>
            item.content.updated_date > parseInt(lastReadTime.withdraw / 1000)
        )
      ) {
        hasUnReadInfo.value = true;
      } else {
        hasUnReadInfo.value = false;
      }
    }
    if (csMsgsData.code === 0 && hasUnReadInfo.value === false) {
      const msgs = JSON.parse(csMsgsData.desc);

      msgs.sort((a, b) => b.create_time - a.create_time);
      const csMsgLastReadTime = localStorage.getItem("csMsg") || 0;
      if (msgs[0].type === "reply" && csMsgLastReadTime < msgs[0].create_time) {
        hasUnReadInfo.value = true;
      }
    }
    if (hasUnReadInfo.value === false) {
      let noticeData = [];
      let readedNoticeData = [];
      if (res[1].code === 0) {
        noticeData = res[1].desc.map((item) => JSON.parse(item));
      }
      if (res[2].code === 0) {
        readedNoticeData = res[2].desc.map((item) => JSON.parse(item));
      }
      if (
        noticeData.some(
          (item) =>
            !readedNoticeData.some(
              (subitem) => subitem.create_time === item.create_time
            )
        )
      ) {
        hasUnReadInfo.value = true;
      }
    }
  } catch {}
};

getNewInfo();
</script>

<style lang="less" scoped>
::v-deep(.van-tabbar) {
  max-width: @--max-width;
  left: 50%;
  transform: translateX(-50%);

  .van-tabbar-item {
    background-color: #ffffff;
  }

  .aicreator-icon,
  .shop-icon,
  .manager-icon {
    height: 1.625rem;
    width: 1.625rem;
  }

  .user-icon {
    height: 1.875rem;
    width: 1.875rem;
  }

  .unread {
    position: relative;
  }
  .unread::after {
    content: "";
    position: absolute;
    top: 0;
    right: 1.5rem;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: red;
  }
}

.agent-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(#ebebeb, #fff);
}
.ai-agent {
  overflow: hidden;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 #cbcbcb;
  z-index: 9999;
}
::v-deep(.agent-popup) {
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: @--max-width;
}
.agent-popup {
  .agent-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0 0.8rem;
    font-family: "aliregular";
    border-radius: 20px 20px 0 0;
    color: @--normal-color-black;
    background: linear-gradient(#f1fcff, #fff);

    .header {
      position: relative;
      display: flex;
      justify-content: space-between;
      height: 3rem;
      line-height: 2rem;

      .header-right {
        font-size: 0.875rem;
      }

      .header-icon {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4.25rem;
        height: 4.25rem;
        border-radius: 50%;
        background-color: #f1f1f1;
      }
    }

    .content {
      overflow: auto;
      width: 100%;
      flex: 1;
      margin-bottom: 1rem;
      .list {
        width: 100%;
        .item {
          width: fit-content;
          max-width: 90%;
          padding: 0.5rem;
          margin-bottom: 0.5rem;
          font-size: 0.875rem;
          border-radius: 10px;
        }
        .item-left {
          color: @--normal-color-black;
          background-color: #fff;
        }
        .item-right {
          margin-left: auto;
          color: #fff;
          background-color: @--main-color;
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 4.8rem;
      padding-bottom: 0.5rem;
      text-align: center;
      font-size: 0.75rem;
      color: @--normal-color-grey;

      .field-wrap {
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 2.6875rem;
        padding: 0 0.5rem;
        border-radius: 10px;
        border: 1px solid @--normal-color-grey-light;

        ::v-deep(.van-field) {
          flex: 1;
          padding: 0;
        }

        .btn {
          width: 2.0625rem;
          height: 2.0625rem;
          margin-left: 0.5rem;
          line-height: 2.0625rem;
          text-align: center;
          font-size: 1.5rem;
          font-weight: 600;
          border-radius: 50%;
          color: #fff;
          background: @--main-color;
        }
      }
    }
  }
}
</style>

<style lang="less">
/* 引入字体文件 */

@font-face {
  font-family: "wendesiti";
  src: url("./font/wendesiti.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "aliregular";
  src: url("./font/Alibaba_PuHuiTi_2.0_55_Regular_55_Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "aliblob";
  src: url("./font/Alibaba_PuHuiTi_2.0_55_Regular_85_Bold.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

::-webkit-scrollbar {
  display: none;
}

// dialog
.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
}
.dialog-enter-active,
.dialog-leave-active {
  transition: all 0.3s linear;
}
.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
}

// fade in left
.fade-in-left-enter-from,
.fade-in-left-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
.fade-in-left-enter-active,
.fade-in-left-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-in-left-enter-to,
.fade-in-left-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.fold-left-enter-active {
  animation-name: fold-left-in;
  animation-duration: 0.5s;
}
.fold-left-leave-active {
  animation-name: fold-left-out;
  animation-duration: 0.5s;
}
.fold-right-enter-active {
  animation-name: fold-right-in;
  animation-duration: 0.5s;
}
.fold-right-leave-active {
  animation-name: fold-right-out;
  animation-duration: 0.5s;
}

@keyframes fold-left-in {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fold-left-out {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes fold-right-in {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fold-right-out {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
</style>
